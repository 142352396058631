/* global sessionStorage, window */

import axios from 'axios';
import logger from '@/scripts/logger';
import Emitter from 'tiny-emitter';
import { v4 as guid } from 'uuid';

import { setupCache } from 'axios-cache-interceptor';
import { jwtDecode } from 'jwt-decode';

const server = import.meta.env.VITE_SERVER_URI;
const cacheDisabled = import.meta.env.VITE_AXIOS_CACHE_DISABLE === 'true';

function JSONTransformer(data) {
    try {
        return typeof data === 'string' ? JSON.parse(data) : data;
    } catch {
        return data;
    }
}

let axiosConfig = {
    baseURL: `${server}/api/`,
    timeout: import.meta.env.VITE_AXIOS_TIMEOUT || 2000,
    transformResponse: [JSONTransformer],
};

let _instance;

if (cacheDisabled) {
    logger.debug(`axios instance data`, axiosConfig);

    _instance = axios.create(axiosConfig);
} else {
    let cacheOptions = {
        ttl: parseInt(import.meta.env.VITE_AXIOS_CACHE_TTL || '10000'),
    };

    logger.debug(`axios cached instance data`, axiosConfig, cacheOptions);

    _instance = setupCache(axios.create(axiosConfig), cacheOptions);
}

const instance = _instance;

export async function getServerBuildDate() {
    if (!instance) return;

    let call = {
        method: 'get',
        baseURL: `${server}`,
        url: `/version.txt`,
        timeout: import.meta.env.VITE_AXIOS_TIMEOUT || 2000,
        //transformResponse: [JSONTransformer],
    };
    let response = await instance(call);

    const regex = /Build date: (.*) \(.*\)/;

    let m;

    if ((m = regex.exec(response.data)) !== null) {
        return new Date(m[1]);
    }
    return null;
}

function isEmpty(str) {
    return !str || str.length === 0;
}

let decodedToken;

function getAuthToken() {
    let authToken = sessionStorage.getItem('authToken');

    decodedToken = authToken ? jwtDecode(authToken) : null;

    return authToken;
}

/*
async function concatUint8Arrays(uint8arrays) {
    const blob = new Blob(uint8arrays);
    const buffer = await blob.arrayBuffer();
    return new Uint8Array(buffer);
}

async function compress2(str) {
    // Convert the string to a byte stream.
    const stream = new Blob([str]).stream();

    // Create a compressed stream.
    const compressedStream = stream.pipeThrough(new CompressionStream('gzip'));

    // Read all the bytes from this stream.
    const chunks = [];
    for await (const chunk of compressedStream) {
        chunks.push(chunk);
    }
    return await concatUint8Arrays(chunks);
}

function compress(string) {
    return new Promise((fulfill, reject) => {
        try {
            const byteArray = new TextEncoder().encode(string);
            const cs = new CompressionStream('gzip');
            const writer = cs.writable.getWriter();

            writer.ready
                .then(() => {
                    return writer.write(byteArray);
                })
                .then(() => {
                    return writer.close();
                })
                .then(() => {
                    fulfill(new Response(cs.readable).arrayBuffer());
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (err) {}
    });
}
*/

class EventListener extends Emitter {
    constructor() {
        super();

        // If we have an auth token, start listening
        if (getAuthToken()) {
            this.start();
        }
    }

    start() {
        if (decodedToken.scope === 'view') return;

        let wssBaseUri = (window.location.protocol === 'https:' ? 'wss:' : 'ws:') + '//' + window.location.host;

        this.openSocket(`${wssBaseUri}/ws/events`, 1000, 1000, 2);
    }

    stop() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }

    async send(msg, ...args) {
        try {
            let data = JSON.stringify(args);

            // Sanitize any tokens
            data = data.replace(/"((?:refresh|access)Token|Authorization)":"(.*?)"/gim, '"$1":"<redacted>"');

            //let buffer = await compress2(data);

            //let buffer = await compress(data);
            //data = { compressed: btoa(String.fromCharCode(...new Uint8Array(buffer))) };

            let payload = JSON.stringify({
                timestamp: new Date().toISOString(),
                message: msg,
                application: 'web',
                environment: import.meta.env.VITE_ENVIRONMENT,
                compressed: true,
                encrypted: false,
                payload: data,
            });

            if (this.ws && this.ws.readyState === 1) {
                this.ws.send(payload);
            }
        } catch (err) {
            console.error(err);
        }
    }

    openSocket(wsURL, waitTimer, waitSeed, multiplier) {
        let authToken = getAuthToken();

        this.ws = new WebSocket(wsURL);

        let that = this;

        function reconnect(why) {
            if (!that.ws) {
                return;
            }

            if (waitTimer < 60000) {
                waitTimer = waitTimer * multiplier;
            }

            logger.debug(`${why}: ${that.ws.url}, next attempt in : ${waitTimer / 1000} seconds`);

            setTimeout(() => {
                that.openSocket(wsURL, waitTimer, waitSeed, multiplier);
            }, waitTimer);
        }

        if (!authToken) {
            return reconnect('Aborted (no auth)');
        }

        logger.debug(`trying to connect to:`, this.ws.url);

        this.ws.onopen = () => {
            waitTimer = waitSeed; //reset the waitTimer on first message

            logger.debug(`connection open to:`, this.ws.url);

            this.ws.onclose = (event) => {
                logger.debug('websocket onclose event =>', event);
                reconnect('Closed');
            };

            this.ws.onmessage = (message) => {
                logger.debug('websocket received message => ', message);

                try {
                    let data = JSON.parse(message.data);

                    let sessionKeyMatch = data.payload?.['sessionKey'] === currentSessionKey;

                    // callback to the parent page
                    this.emit('new-message', data.message, data.payload, sessionKeyMatch);
                } catch (err) {
                    logger.error(err);
                }
            };
        };

        this.ws.onerror = (event) => {
            logger.debug('websocket onerror event =>', event);
            try {
                if (this.ws) {
                    this.ws.onclose = null;
                    this.ws.onmessage = null;
                }
            } catch (err) {
                logger.error(err);
            }
            reconnect('Error');
        };
    }
}

const eventListener = new EventListener();

logger.on('debug', (args) => {
    eventListener.send('logging.debug', ...args).catch();
});

logger.on('info', (args) => {
    eventListener.send('logging.info', ...args).catch();
});

logger.on('warn', (args) => {
    eventListener.send('logging.warn', ...args).catch();
});

logger.on('error', (args) => {
    eventListener.send('logging.error', ...args).catch();
});

const currentSessionKey = guid();

class eventProcessorQueue {
    processing = false;
    currentQueue = [];

    constructor() {
        setInterval(() => {
            if (!this.processing) {
                this.processing = true;
                this.processor()
                    .catch()
                    .finally(() => {
                        this.processing = false;
                    });
            }
        }, 500);
    }

    async processor() {
        while (this.currentQueue.length) {
            let cb = this.currentQueue.shift();
            try {
                await cb();
            } catch (err) {
                logger.error(err);
            }
        }
    }

    push(cb) {
        this.currentQueue.push(cb);
    }
}

const createApi = () => {
    instance.interceptors.request.use(
        async (config) => {
            let authToken = getAuthToken();

            config.headers = {
                'X-SessionKey': currentSessionKey,
            };

            let isAdmin = sessionStorage.getItem('admin') === 'true';

            if (isAdmin) {
                config.headers['x-admin'] = true;
                config.headers['x-admin-org'] = sessionStorage.getItem('admin.orgId') ?? '*';
            }

            if (!isEmpty(authToken)) {
                config.headers['Authorization'] = `Bearer ${authToken}`;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    // noinspection JSUnusedGlobalSymbols
    return {
        isAdminCall() {
            return sessionStorage.getItem('admin') === 'true';
        },

        getSessionKey() {
            return currentSessionKey;
        },

        pollEvents() {
            eventListener.start();
        },

        stopPolling() {
            eventListener.stop();
        },

        events(callback) {
            let epq = new eventProcessorQueue();

            eventListener.on('new-message', (message, payload, sessionKeyMatch) => {
                try {
                    function f(...args) {
                        return new Promise((fulfill, reject) => {
                            callback(...args)
                                .then(() => {
                                    fulfill();
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    }

                    const task = () => f(message, payload, sessionKeyMatch);

                    epq.push(task);
                } catch (err) {
                    logger.error(err);
                }
            });
        },

        notify(event, payload) {
            return eventListener.send(event, payload);
        },

        loadFirebaseConfig() {
            return instance.get(`/config/firebase`);
        },

        createOrg(data) {
            return instance.post('orgs', data);
        },

        createTrialOrg(data) {
            return instance.post('orgs/trial', data);
        },

        getOrgs() {
            let url = `orgs`;
            let params = [];

            if (this.isAdminCall()) {
                //url = 'admin/' + url;
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            return instance.get(url, { cache: false });
        },

        getOrg(orgId) {
            let url = `orgs/${orgId}`;
            let params = [];

            if (this.isAdminCall()) {
                params.push('fields=address,billing');
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            return instance.get(url);
        },

        useOrg(orgId) {
            return instance.get(`orgs/${orgId}/use`);
        },

        getCurrentOrg() {
            return instance.get(`orgs/current`, { cache: false });
        },

        updateOrg(data) {
            return instance.patch(`orgs/current`, data);
        },

        addOrgUser(email) {
            return instance.post(`orgs/users`, { email: email });
        },

        deleteOrgUser(userId) {
            return instance.delete(`orgs/users/${userId}`);
        },

        setUserPermission(userId, p) {
            return instance.post(`orgs/users/${userId}/permissions`, p);
        },

        lookupOrg(domain) {
            return instance.post(`orgs/find`, { domain: domain });
        },

        createCase(data) {
            let _data = {
                orgId: data.orgId,
                paymentId: data.paymentId,
                caseName: data.name,
                projectCode: data.code,
                users: data.users,
                settings: data.settings,
                lisaEnabled: data.lisa,
            };

            if (!isEmpty(data.demo)) _data['demo'] = data.demo;

            if (!isEmpty(data.date)) _data['trialDate'] = data.date;

            if (!isEmpty(data.venue)) _data['trialVenue'] = data.venue;

            if (!isEmpty(data.notes)) _data['caseNotes'] = data.notes;

            return instance.post(`cases`, _data);
        },

        addCaseUsers(id, userIds, representing) {
            return instance.post(`cases/${id}/users/add`, {
                userIds: userIds,
                representing: representing,
            });
        },

        createCaseUser(id, emailAddress, representing) {
            return instance.post(`cases/${id}/users/add`, {
                emailAddress: emailAddress,
                representing: representing,
            });
        },

        setCaseUserRole(id, userId, newRole) {
            return instance.post(`cases/${id}/users/${userId}`, {
                role: newRole,
            });
        },

        deleteCaseUser(id, userId) {
            return instance.delete(`cases/${id}/users/${userId}`);
        },

        getCase(id, addFields = null) {
            return this.getCases({ id: id }, addFields);
            /*
            return instance.get(`cases/${id}` )
            */
        },

        getCases(filter, addFields = null) {
            let url = `cases`;
            let params = [];

            if (this.isAdminCall()) {
                let orgId = sessionStorage.getItem('admin.orgId');

                if (!orgId) throw 'missing admin.orgId';

                params.push(`orgId=${orgId}`);

                //url = 'admin/' + url;
            }

            if (filter) {
                if (filter.id) {
                    params.push(`id=${filter.id}`);
                } else {
                    params.push(`filter=${JSON.stringify(filter)}`);
                }
            }

            if (addFields) {
                if (!Array.isArray(addFields)) addFields = [addFields];
                params.push(`fields=${[...addFields].join(',')}`);
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            return instance.get(url, { cache: false });
        },

        updateSettings(id, data) {
            return instance.patch(`cases/${id}`, { settings: data });
        },

        updateCase(id, data) {
            let _data = {
                paymentId: data.paymentId,
                caseName: data.name,
                trialDate: data.date,
                trialVenue: data.venue,
                caseNotes: data.notes,
                projectCode: data.code,
                lisaEnabled: data.lisa,
            };

            return instance.patch(`cases/${id}`, _data);
        },

        deleteCase(id) {
            return instance.delete(`cases/${id}`);
        },

        archiveCase(id) {
            return instance.patch(`cases/${id}`, { archive: true });
        },

        restoreCase(id) {
            return instance.patch(`cases/${id}`, { archive: false });
        },

        getDepositions(caseId) {
            return instance.get(`depositions?case=${caseId}`, { cache: false });
        },

        createDeposition(data) {
            return instance.post(`depositions`, data);
        },

        getDeposition(id) {
            return instance.get(`depositions/${id}`);
        },

        updateDeposition(id, data) {
            return instance.patch(`depositions/${id}`, data);
        },

        attachVideo(id, data) {
            return instance.put(`depositions/${id}/video`, data);
        },

        getVideo(id) {
            return instance.get(`depositions/${id}/video`);
        },

        deleteVideo(id) {
            return instance.delete(`depositions/${id}/video`);
        },

        transcribeVideo(id) {
            return instance.get(`depositions/${id}/video/transcription`);
        },

        deleteDeposition(id) {
            return instance.delete(`depositions/${id}`);
        },

        shareDeposition(id, link, emailAddresses, options) {
            return instance.post(`depositions/${id}/share`, {
                link,
                emailAddresses,
                options,
            });
        },

        deleteDepositionShare(id, invitationId) {
            return instance.delete(`depositions/${id}/share/${invitationId}`);
        },

        /*
                modifyDepositionShare(id, invitationId, options) {
                    return instance.patch(`depositions/${id}/share?invitation=${invitationId}`, {options});
                },
        */
        getUnprocessedTranscripts(caseId) {
            return instance.get(`transcripts?case=${caseId}&state=not-processed`);
        },

        getTranscript(id) {
            return instance.get(`transcripts/${id}`);
        },

        getProcessedTranscript(id) {
            return instance.get(`transcripts/${id}/processed`, {
                cache: { ttl: 1000 * 30 },
            });
        },

        getTranscriptMetadata(id, fields) {
            let query = '';

            if (fields) {
                if (!Array.isArray(fields)) fields = [fields];

                query = `?fields=${fields.join(',')}`;
            }

            return instance.get(`transcripts/${id}/metadata${query}`);
        },

        createTranscript(caseId, data) {
            return instance.post(`transcripts/${caseId}`, data);
        },

        updateTranscript(id, data) {
            return instance.put(`transcripts/${id}/metadata`, data);
        },

        deleteTranscript(id, data) {
            return instance.delete(`transcripts/${id}`, data);
        },

        resetTranscript(id) {
            return instance.get(`transcripts/${id}/reset`);
        },

        reprocessTranscript(id) {
            return instance.get(`transcripts/${id}/reprocess`);
        },

        createUser(data) {
            return instance.post(`users`, data);
        },

        getLoginMethod(email) {
            return instance.post(`users/login/provider/method`, { email });
        },

        setRoles(roles) {
            return instance.post(`users/roles/set`, { roles });
        },

        //TODO: Future SAML
        /*
                getLoginMigrate(){
                    return instance.get(`users/login/provider/migrate`);
                },
        */
        getCurrentUser() {
            return new Promise((fulfill, reject) => {
                if (!getAuthToken()) {
                    return fulfill();
                }

                instance
                    .get(`users/me`, { cache: false })
                    .then((data) => {
                        fulfill(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        findUser(id, caseId, orgId) {
            let url = `users/${id}`;

            if (this.isAdminCall()) {
                //url = 'admin/' + url;
            }

            let params = [];

            if (!isEmpty(caseId)) {
                params.push(`case=${caseId}`);
            }

            if (!isEmpty(orgId)) {
                params.push(`org=${orgId}`);
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            return instance.get(url, { cache: { ttl: 1000 * 60 * 5 } });
        },

        getUsers() {
            let url = `users`;

            return instance.get(url);
        },

        getUser(userId, orgId = null) {
            let url = `users/${userId}`;
            let params = [];

            if (this.isAdminCall()) {
                params.push(`org=${orgId}`);
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            return instance.get(url);
        },

        getShareInvitations(caseId = null) {
            let url = `invitations?type=deposition-share`;

            if (!isEmpty(caseId)) {
                url += `&case=${caseId}`;
            }

            return instance.get(url);
        },

        findInvitation(id, caseId) {
            let url = `invitations/${id}`;

            if (!isEmpty(caseId)) {
                url += `?case=${caseId}`;
            }

            return instance.get(url);
        },

        verifyInvitation(data) {
            return instance.post(`invitations/check`, data);
        },

        retrieveInvitation(invitationId) {
            return instance.get(`invitations/${invitationId}`);
        },

        acceptInvitation(data) {
            return instance.post(`orgs/invitation/accept`, data);
        },

        updateProfile(data) {
            return instance.post(`users/profile`, data);
        },

        uploadFiles(caseId, formData, onUploadProgress = null) {
            return new Promise((fulfill, reject) => {
                const headers = { 'Content-Type': 'multipart/form-data' };
                instance
                    .post(`transcripts/${caseId}`, formData, {
                        headers,
                        onUploadProgress,
                    })
                    .then((data) => {
                        fulfill(data);
                    })
                    .catch((err) => {
                        reject(err.response?.data || err);
                    });
            });
        },

        getBillingDetails(id) {
            return instance.get(`billing/payment/sources/${id}/details`);
        },

        getPaymentSource(id) {
            return instance.get(`billing/payment/sources?id=${id}`);
        },

        getPaymentSources() {
            let url = `billing/payment/sources`;
            let params = [];

            if (this.isAdminCall()) {
                //url = 'admin/' + url;
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            return instance.get(url);
        },

        createPaymentSource(data) {
            return instance.post(`billing/payment`, data);
        },

        updatePaymentSource(id, data) {
            return instance.put(`billing/payment/${id}`, data);
        },

        deletePaymentSource(id) {
            return instance.delete(`billing/payment/${id}`);
        },

        getBillingInvoices(caseIds, options) {
            let url = `billing/invoices/search`;

            let params = [];

            if (options?.page) {
                params.push(`page=${options.page}`);
            }

            if (options?.limit) {
                params.push(`limit=${options.limit}`);
            }

            if (params.length) {
                url += `?${params.join('&')}`;
            }

            //params.push(`cases=[${caseIds.join(',')}]`);

            return instance.post(url, {
                cases: caseIds,
                filter: options?.filter,
            });
        },

        retryInvoicePayment(invoiceId) {
            return instance.post(`billing/invoices/${invoiceId}/retry`);
        },

        verifyInvoicePayment(invoiceId) {
            return instance.post(`billing/invoices/${invoiceId}/verify`);
        },

        disputeCharge(invoiceId, reason) {
            return instance.post(`billing/invoices/${invoiceId}/dispute`, {
                data: reason,
            });
        },

        getInvoiceLink(invoiceId) {
            return instance.get(`billing/invoices/${invoiceId}/link`);
        },

        signInWithInvitationCode(invitationCode, email, passcode) {
            return instance.post(`auth/login`, {
                code: invitationCode,
                email: email,
                passcode: passcode,
            });
        },

        requestNewToken(refreshToken) {
            return instance.post(`auth/token/refresh`, {
                refreshToken: refreshToken,
            });
        },

        indexTranscript(depositionId, transcriptId) {
            return instance.post(`/lisa/index/transcript`, { depositionId, transcriptId });
        },

        summarizeTranscript(transcriptId, progress) {
            return instance.post(
                `/lisa/summarize/transcript`,
                { transcriptId },
                {
                    onDownloadProgress: (progressEvent) => {
                        const dataChunk = progressEvent?.event?.currentTarget?.response;
                        if (progress && dataChunk) {
                            progress(dataChunk);
                        }
                    },
                },
            );
        },

        askLisa(caseId, transcriptId, data, progress) {
            let buffer = '';
            let offset = 0;
            return instance.post(
                `/lisa/ask`,
                { caseId, transcriptId, what: data.what, prompt: data.prompt },
                {
                    timeout: 60000,
                    onDownloadProgress: (progressEvent) => {
                        if (progressEvent?.event?.target?.status === 200) {
                            const dataChunk = (progressEvent?.event?.target?.response ?? '').substring(offset);
                            offset += dataChunk.length;
                            if (progress && dataChunk) {
                                buffer = dataChunk;

                                if (!dataChunk.endsWith('\n')) {
                                    return;
                                }

                                let json = buffer.split('\n');

                                let processed = false;

                                json.forEach((chunk) => {
                                    try {
                                        if (chunk.length > 0) {
                                            processed = true;
                                            progress(JSON.parse(chunk));
                                        }
                                    } catch (err) {
                                        debugger;
                                        //logger.error(err, 'block', chunk);
                                        //buffer += chunk;
                                    }
                                });

                                if (processed) buffer = '';
                            }
                        } else {
                            //progress({ error: `We've encountered an error and are unable to process your question.` });
                        }
                    },
                },
            );
        },
    };
};

export default createApi();
