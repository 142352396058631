/* global window, document, localStorage */
import { defineStore, storeToRefs } from 'pinia';
import { ref, computed, watch } from 'vue';

import logger from '@/scripts/logger';
import { useUserStore } from '@/stores/userStore.js';

export const useSettingsStore = defineStore('settings', () => {
    const userStore = useUserStore();

    const { userId } = storeToRefs(userStore);

    const _model = ref({
        automaticLogout: 30,
        automaticSaving: 5,
        locale: 'en-US',
        dateFormat: 'system',
        transcriptFilter: 'all',
        defaultSort: { dateCreated: { direction: 1 } },
        pinnedEntries: {},
        exportHighlight: 'column',
        cardView: false,
        caseFilter: 'all',
        caseSort: { dateCreated: { direction: 1 } },
        pinnedCases: [],
        invoicesPerPage: 10,
        stripedTable: false,
        localVideos: {},
    });

    if (process.env.NODE_ENV === 'development') {
        _model[prompt] = '';
    }

    watch(userId, () => {
        let value = localStorage.getItem(`${userId.value}_settings`);

        if (value) {
            let settings = JSON.parse(value);

            logger.debug(`settingsStore watch(userId, () ) => `, settings);

            _model.value = Object.assign(_model.value, settings);
        }
    });

    watch(
        _model,
        () => {
            localStorage.setItem(`${userId.value}_settings`, JSON.stringify(_model.value));
        },
        { deep: true },
    );

    const automaticLogout = computed({
        get: () => parseInt(_model.value?.automaticLogout),
        set: (value) => (_model.value.automaticLogout = value),
    });

    const automaticSaving = computed({
        get: () => parseInt(_model.value?.automaticSaving),
        set: (value) => (_model.value.automaticSaving = value),
    });

    const displayMode = computed({
        get: () => localStorage.getItem('displayMode') ?? 'system',
        set: (value) => {
            localStorage.setItem('displayMode', value);
            setDisplayMode(value);
        },
    });

    const locale = computed({
        get: () => _model.value?.locale,
        set: (value) => (_model.value.locale = value),
    });

    const dateFormat = computed({
        get: () => _model.value?.dateFormat,
        set: (value) => (_model.value.dateFormat = value),
    });

    const transcriptFilter = computed({
        get: () => _model.value?.transcriptFilter,
        set: (value) => (_model.value.transcriptFilter = value),
    });

    const defaultSort = computed({
        get: () => _model.value?.defaultSort,
        set: (value) => (_model.value.defaultSort = value),
    });

    const pinnedEntries = computed({
        get: () => {
            let value = _model.value?.pinnedEntries;
            if (Array.isArray(value)) return value;
            value = Object.keys(value).reduce((acc, cur) => {
                return acc.concat(value[cur]);
            }, []);
            _model.value.pinnedEntries = value;
            return value;
        },
        set: (value) => (_model.value.pinnedEntries = value),
    });

    const exportHighlight = computed({
        get: () => _model.value?.exportHighlight,
        set: (value) => (_model.value.exportHighlight = value),
    });

    const cardView = computed({
        get: () => _model.value?.cardView,
        set: (value) => (_model.value.cardView = value),
    });

    const caseFilter = computed({
        get: () => _model.value?.caseFilter,
        set: (value) => (_model.value.caseFilter = value),
    });

    const caseSort = computed({
        get: () => _model.value?.caseSort,
        set: (value) => (_model.value.caseSort = value),
    });

    const pinnedCases = computed({
        get: () => _model.value?.pinnedCases,
        set: (value) => (_model.value.pinnedCases = value),
    });

    const invoicesPerPage = computed({
        get: () => _model.value?.invoicesPerPage,
        set: (value) => (_model.value.invoicesPerPage = value),
    });

    const stripedTable = computed({
        get: () => _model.value?.stripedTable,
        set: (value) => (_model.value.stripedTable = value === 'true'),
    });

    const localVideos = computed({
        get: () => _model.value?.localVideos,
        set: (value) => (_model.value.localVideos = value),
    });

    function setDisplayMode(value) {
        const container = document.documentElement;

        switch (value) {
            case 'system':
                if (window.matchMedia('(prefers-color-scheme: light)').matches) {
                    container.setAttribute('data-bs-theme', 'light');
                }

                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    container.setAttribute('data-bs-theme', 'dark');
                }
                break;
            case 'light':
                container.setAttribute('data-bs-theme', 'light');
                break;
            case 'dark':
                container.setAttribute('data-bs-theme', 'dark');
                break;
        }
    }

    let exports = {
        automaticLogout,
        automaticSaving,
        displayMode,
        locale,
        dateFormat,

        transcriptFilter,
        defaultSort,
        pinnedEntries,

        exportHighlight,
        cardView,

        caseFilter,
        caseSort,
        pinnedCases,

        invoicesPerPage,

        stripedTable,

        localVideos,
    };

    if (process.env.NODE_ENV === 'development') {
        exports.prompt = computed({
            get: () => _model.value?.prompt,
            set: (value) => (_model.value.prompt = value),
        });
    }

    return exports;
});
